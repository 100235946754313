import { ChakraProvider, Grid, GridItem, HStack, Link } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Mail } from "@styled-icons/foundation/Mail";
import { SocialGithub } from "@styled-icons/foundation/SocialGithub";
import { SocialInstagram } from "@styled-icons/foundation/SocialInstagram";
import { SocialLinkedin } from "@styled-icons/foundation/SocialLinkedin";
import "focus-visible/dist/focus-visible";
import Head from "next/head";
import { Router } from "next/router";
import * as React from "react";
import ActiveLink from "../components/ActiveLink";
import useRegisterServiceWorker from "../hooks/register-sw";
import "../styles/globals.css";
import theme from "../theme";
import { sentryInit } from "../utils";

Router.events.on("routeChangeComplete", (url) => {
  window.analytics.page(url);
});

sentryInit();

const date = new Date();

const NavLink = styled(Link)`
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &.active {
    text-shadow: -0.06ex 0 currentColor, 0.06ex 0 currentColor;
  }
`;

function MyApp({ Component, pageProps, err }) {
  useRegisterServiceWorker();
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=yes, viewport-fit=cover"
        />
        <meta httpEquiv="Content-Language" content="en" />
        <meta name="application-name" content="Matthew Costabile" />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />

        <meta name="apple-mobile-web-app-title" content="Matthew Costabile" />
        <meta name="description" content="Matthew Costabile" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="msapplication-config"
          content="/static/icons/browserconfig.xml"
        />
        <meta name="msapplication-TileColor" content="#1A202C" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#FFFFFF" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/icons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/icons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/icons/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/manifest.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/icons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/static/icons/favicon.ico" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://mattc.io" />
        <meta name="twitter:title" content="Matthew Costabile" />
        <meta name="twitter:description" content="Matthew Costabile" />
        <meta
          name="twitter:image"
          content="https://mattc.io/static/icons/android-chrome-192x192.png"
        />
        <meta name="twitter:creator" content="@mattcosta7" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Matthew Costabile" />
        <meta property="og:description" content="Matthew Costabile" />
        <meta property="og:site_name" content="Matthew Costabile" />
        <meta property="og:url" content="https://mattc.io" />
        <meta
          property="og:image"
          content="https://mattc.io/static/icons/apple-touch-icon.png"
        />
      </Head>
      <ChakraProvider theme={theme}>
        <Grid
          gridTemplateColumns="1fr"
          gridTemplateRows="56px 1fr 56px"
          gridTemplateAreas={`
        'header'
        'main'
        'footer'
      `}
          height="100%"
          width="100%"
        >
          <GridItem as="nav" gridArea="header" marginLeft="auto">
            <HStack as="ul" listStyleType="none" spacing={3} height="100%">
              <li>
                <ActiveLink href="/">
                  <NavLink padding={3}>Home</NavLink>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  href="/photos"
                  isActive={(router) => {
                    return router.pathname.startsWith("/photos");
                  }}
                >
                  <NavLink padding={3}>Photos</NavLink>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href="/resume">
                  <NavLink padding={3}>Resume</NavLink>
                </ActiveLink>
              </li>
            </HStack>
          </GridItem>
          <GridItem as="main" gridArea="main" overflow="auto" padding={2}>
            <Component {...pageProps} err={err} />
          </GridItem>
          <GridItem as="footer" gridArea="footer" textAlign="center">
            <HStack spacing={2} justifyContent="center">
              <a
                href="https://github.com/mattcosta7"
                target="__blank"
                rel="noopener noreferrer"
                title="Check out @mattcosta7 on GitHub"
              >
                <SocialGithub
                  title="Check out @mattcosta7 on GitHub"
                  height="1.5em"
                />
              </a>
              <a
                href="https://instagram.com/mattcosta7"
                target="__blank"
                rel="noopener noreferrer"
                title="Check out @mattcosta7 on Instagram"
              >
                <SocialInstagram
                  title="Check out @mattcosta7 on Instagram"
                  height="1.5em"
                />
              </a>
              <a
                href="https://linkedin.com/in/costabilematthew"
                target="__blank"
                rel="noopener noreferrer"
                title="Check out Matthew Costabile on Linkedin"
              >
                <SocialLinkedin
                  title="Check out Matthew Costabile on Linkedin"
                  height="1.5em"
                />
              </a>
              <a
                href="mailto:matt+website@mattc.io"
                target="__blank"
                rel="noopener noreferrer"
                title="Email me!"
              >
                <Mail title="Email me!" height="1.5em" />
              </a>
            </HStack>
            <p>mattc.io {date.getFullYear()} &copy;</p>
          </GridItem>
        </Grid>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
