// theme.js
// 1. import `extendTheme` function
import { extendTheme, Theme } from "@chakra-ui/react";
// 2. Add your color mode config
const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};
// 3. extend the theme
const theme: Theme = extendTheme({ config });
export default theme;
