import NextLink, { LinkProps } from "next/link";
import { NextRouter, useRouter } from "next/router";
import * as React from "react";
import cx from "classnames";

export interface ActiveLinkProps extends LinkProps {
  children: React.ReactNode;
  isActive?: boolean | ((router: NextRouter, props: LinkProps) => boolean);
  activeClassName?: string;
}

export default function ActiveLink({
  children,
  isActive,
  activeClassName = "active",
  ...props
}: ActiveLinkProps) {
  const router = useRouter();

  const child: React.ReactNode = React.useMemo(() => {
    let _child = React.Children.only(children);

    /** React.Children.only should type to make this happen,
     * but currently doesnt so we make this check to ensure
     * that only elements are passed as children to active link
     */
    /* istanbul ignore if */
    if (!React.isValidElement(_child)) throw new Error("Invalid child");

    let activeState: boolean;
    if (typeof isActive === "boolean") {
      activeState = isActive;
    } else if (typeof isActive === "function") {
      activeState = isActive(router, props);
    } else {
      activeState = router.asPath === props.href || router.asPath === props.as;
    }

    return React.cloneElement(_child, {
      className: cx(_child.props.className, {
        [activeClassName]: activeState,
      }),
    });
  }, [children, activeClassName, router.asPath, props.href, props.as]);

  return (
    <NextLink passHref {...props}>
      {child}
    </NextLink>
  );
}
